import React, { useEffect, useState } from 'react';
import '../SplashScreen.css'; // Import your CSS file
import heart_logo from '../assets/images/logo.png' // Import your logo image

const SplashScreen = () => {
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    // Simulate an asynchronous operation or wait for your app to load here
    setTimeout(() => {
      setFadeOut(true);
    }, 500); // Adjust the time as needed
  }, []);

  const splashScreenStyle = {
    backgroundColor: '#ECFDFF', // Background color
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    opacity: fadeOut ? 0 : 1, // Apply fade-out effect
    transition: 'opacity 1s ease-in-out', // Transition duration and easing
  };

  return (
    <div style={splashScreenStyle}>
      <img src={heart_logo} alt="Checking your browser..." />
    </div>
  );
};

export default SplashScreen;