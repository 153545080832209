import React from 'react'

const Features = () => {
    return (
        <>
            <div className="container mx-auto pb-24 px-4 md:px-12 lg:px-12" id="feature">
                <div className="sml:w-[100%] md:w-[80%] lg:w-[80%] xl:w-[80%] 2xl:w-[60%] mx-auto">
                    <p className="ff text-center text-[#383838] text-[20px] font-[500px] tracking-[10px] leading-normal">
                        FEATURES
                    </p>
                    <p className="pb-6 text-[#383838] ff leading-[28px] lg:leading-[48px] sml:px-10 lg:px-20 xl:px-20 text-center sm:text-[20px] sml:text-[20px] lg:text-[30px] xl:text-[40px] font-bold mt-6">
                        An Intuitive & Privacy Focused Way To Manage Your Health
                    </p>
                    <p className="ff mt-10 text-center px-5 text-[16px] sml:text-[16px] md:text-[18px] lg:text-[18px] xl:text-[18px] text-[#817e7e]">
                        Discover a clean and intuitive way to track and manage your medical records in a single place. With delightful interactions and a beautiful and friendly interface, Aayu Care is the best medical records locker for you.
                    </p>
                </div>
            </div>
           
        </>
    )
}

export default Features