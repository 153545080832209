import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga4";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const root = ReactDOM.createRoot(document.getElementById("root"));
ReactGA.initialize("G-WL8NEWLEG9");
const firebaseConfig = {
  apiKey: "AIzaSyDam5C5ZptK_t2miPZBxI1q-ApzW4IrdDA",
  authDomain: "aayu-398017.firebaseapp.com",
  projectId: "aayu-398017",
  storageBucket: "aayu-398017.appspot.com",
  messagingSenderId: "816115953525",
  appId: "1:816115953525:web:4ab6485ac8fb78f9c8ad3c",
  measurementId: "G-276ZYD6VGN",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
