import React from 'react'
import heart_logo from '../assets/images/logo.png'
const Best_Medical = () => {

    const handleButtonClick = () => {
        window.open('https://bit.ly/AayuCareBeta', '_blank'); // Open in a new tab
    };

    return (
        <>
            <div className="container mx-auto pb-24 px-4 lg:px-12">
                <div className="md:w-[95%] lg:w-[75%] xl:w-[65%] 2xl:w-[50%] mx-auto flex flex-col md:flex-row justify-between rounded-[25px] bg-[#d4f0f4] shadow-inner p-8 md:p-12">
                    <div className="md:w-[55%]">
                        <p className="text-[#4c4c4c] font-bold leading-[40px] text-[32px] ff md:text-[32px] pb-6">
                        The best medical records locker</p>
                        <p className="pb-8 text-[#817F7F] text-[20px] hidden md:block">
                            Get an early access on the future of medical records management.
                            Be the first few to get our early access beta
                        </p>
                        <button onClick={handleButtonClick} className="hidden md:block bg-[#F74E86] text-[12px] rounded-[5px] text-white py-2 px-6">
                            Apply for Beta Access
                        </button>
                    </div>
                    <div className="md:w-[40%] flex justify-center md:justify-end">
                        <img className="mr-0 w-[200px] h-[200px]" src={heart_logo} alt="heart image" />
                    </div>
                    <button onClick={handleButtonClick} className="block md:hidden bg-[#F74E86] mt-6 text-[12px] sml:rounded-[8px] rounded-[5px] shadow-sm text-white sml:w-[55%] sml:py-3 sm:py-3 px-6 w-[70%] mx-auto">
                        Apply for Beta Access
                    </button>
                </div>
            </div>


           
        </>
    )
}

export default Best_Medical