import React from 'react'

const Footer = () => {
    return (
        <>
            <div className="bg-[#D1FAFF] pb-24 py-12">
                <footer className="text-white flex justify-between container mx-auto px-12">
                    <div className="md:w-full lg:w-[85%] 2xl:w-[54%] mx-auto flex flex-col md:flex-row justify-between">
                        <div className="md:w-[32%] hidden md:block">
                            <h5 className="text-[#383838] font-bold ff text-[16px] mb-3">
                                Aayu Care
                            </h5>
                            <ul>
                                <li className="text-[#A0A0A0] ff text-[16px] mb-3">
                                    © 2023. All Rights Reserved
                                </li>
                            </ul>
                        </div>
                        <div className="md:w-[20%] mt-12 md:mt-0">
                            <h5 className="text-[#4C4C4C] ff font-bold text-[16px] mb-3">
                                Site Map
                            </h5>
                            <ul>
                                <li className="text-[#A0A0A0] ff hover:text-[#383838] text-[16px] mb-3 cursor-pointer">
                                    <a href="https://bit.ly/AayuCareBeta"  target="_blank">Register For Beta</a>
                                </li>
                                <li className="text-[#A0A0A0] ff hover:text-[#383838] text-[16px] mb-3 cursor-pointer">
                                    <a href="#feature">Features</a>
                                </li>
                                
                                <li className="text-[#A0A0A0] ff hover:text-[#383838] text-[16px] mb-3 cursor-pointer">
                                    <a href="#faq">FAQs</a>
                                </li>
                            </ul>
                        </div>
                        <div className="md:w-[20%] mt-12 md:mt-0">
                            <h5 className="text-[#383838] ff font-bold text-[16px] mb-3">
                                Contact
                            </h5>
                            <ul>
                                <li className="text-[#A0A0A0] ff hover:text-[#383838] text-[16px] mb-3 cursor-pointer">
                                    <a href="https://twitter.com/aayucarehq" target="_blank">Twitter</a>
                                </li>
                                <li className="text-[#A0A0A0] ff hover:text-[#383838] text-[16px] mb-3 cursor-pointer">
                                    <a href="https://www.linkedin.com/company/aayucarehq/" target="_blank" >Linkedin</a>
                                </li>
                                <li className="text-[#A0A0A0] ff hover:text-[#383838] text-[16px] mb-3 cursor-pointer">
                                    <a href="mailto:hi@aayu.care" target="_blank">Email</a>
                                </li>

                            </ul>
                        </div>
                        <div className="md:w-[32%] mt-12 block md:hidden">
                            <h5 className="text-[#383838] ff font-bold text-[16px] mb-3">
                                Aayu Care
                            </h5>
                            <ul>
                                <li className="text-[#A0A0A0] ff text-[16px] mb-3">
                                    © 2023. All Rights Reserved
                                </li>
                            </ul>
                        </div>
                    </div>
                </footer>
            </div>

        
        </>
    )
}

export default Footer