import { useState } from "react";
import React from 'react'
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


const FAQS = () => {
    const accordionItems = [
        {
            id: 0,
            title: 'What does Aayu Care mean?',
            content: "\"Aayu\" is a Hindi word, meaning \'age\' or \'lifespan\'. Aayu Care signifies a commitment to monitoring and enhancing one's overall health throughout their life.<br></br>If you've ever struggled with those cumbersome medical record files, you\'re going to love Aayu Care! We\'ve built it from the ground up with a strong emphasis on user privacy. Be sure to sign up for our beta access!",
        },
        {
            id: 1,
            title: 'Why are we building this?',
            content: "In India, unlike some Western countries, patients bear the responsibility of maintaining their own medical histories. This can be challenging, especially when changing doctors, seeking second medical opinions, or filing for insurance claims. Even in emergency care situations, doctors often require your medical history for accurate diagnosis. We believe that during critical moments, you should have swift access to your health information for faster treatment. We understand these challenges firsthand, having encountered them in our own healthcare journeys.<br></br>Recognising the challenges faced by individuals, we are committed to building a secure and convenient solution that allows our users to manage all their health details in one place.",
        },
        {
            id: 2,
            title: 'How do we protect your confidential health data?',
            content: "We understand that your health data is truly personal. Our top priority is user privacy. Our system is built with strong end-to-end encryption, similar to what industry leaders like Apple and WhatsApp use. This means our servers can\'t access or monetize your confidential health data. We aim to revolutionize healthcare apps, providing a premium user experience while maintaining strict privacy standards.<br></br>For those interested in the technical details, we\'ll soon release a whitepaper with more information on our encryption methods.",
        },
        {
            id: 3,
            title: 'How can users share their medical records with doctors or insurers?',
            content: "We are proud to support the Government of India's initiative, <a href=\"https://abdm.gov.in/\" target=\"_blank\" >ABHA - Ayushman Bharat Health Account.</a> ABHA simplifies secure sharing of medical documents with doctors and insurers in just one click. This initiative benefits everyone involved. Patients no longer need to manage cumbersome medical files, while doctors gain access to comprehensive patient histories for faster and more accurate diagnoses. With over 45 crore ABHA accounts already created in India and lacs of hospitals on board, it's a game-changer for inclusive healthcare access and user convenience.",
        },
    ];
    return (
        <>
            <div className="container mx-auto pb-24 px-4 md:px-12 lg:px-12 mt-20" id="faq">
                <div className="sm:w-[100%] sml:w-[100%] md:w-[90%] lg:w-[70%] xl:w-[60%] 2xl:w-[60%] mx-auto">
                    <p className="ff text-center text-[#383838] text-[20px] font-[500px] tracking-[10px] leading-normal">
                        FAQS
                    </p>
                    <div className="mt-12 relative">
                        {
                            accordionItems.map((val, index) => (
                               <Accordion index={val.id}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${val.id}-content`}
        id={`panel${val.id}-header`}
      >
        <Typography>{val.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          <div
            key={index}
            dangerouslySetInnerHTML={{ __html: val.content.replace(/<a /g, '<a style="color: #6cb8c6; text-decoration: underline;" ') }}
          />
        </Typography>
      </AccordionDetails>
    </Accordion>

                            ))
                        }

                    </div>
                </div>

            </div>
        </>
    )
}

export default FAQS