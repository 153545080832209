import React from 'react'
import img_1 from '../assets/images/Vector 1.png'
import img_2 from '../assets/images/desktop_top.png'
import mobile_top from '../assets/images/mobile_top.png'
const TopSection = () => {
      const handleButtonClick = () => {
        window.open('https://bit.ly/AayuCareBeta', '_blank'); // Open in a new tab
    };
    return (
        <>
            <div className="pb-24 mt-20">
                <div className="sml:container md:container lg:container xl:container mx-auto md:pt-16 md:px-12 lg:px-12 lg:pt-12  ">
                    <div className="sm:w-[100%] sml:w-[80%] md:w-[85%] lg:w-[85%] xl:w-[60%] mx-auto relative">
                        <p className="text-[#383838] ff px-10 md:px-0 lg:px-0 drop-shadow-md leading-tight md:leading-tight lg:leading-tight text-[38px] 
                        sm:text-[39px] sml:text-[38px] md:text-[45px] lg:text-[64px] text-center font-[600] md:font-bold lg:font-bold  ">
                            All Your Health Records In One Safe Place
                        </p>
                        <img
                            className="mx-auto w-[220px] lg:w-[290px] pr-[32px]"
                            src={img_1}
                            alt=""
                        />

                        <p className="text-[#817F7F] ff  text-center md:px-20 lg:px-24 pt-2 text-[16px] sml:text-[14px] lg:text-[20px]">
                            Aayu Care gathers, stores and analyzes all your health records and
                            automatically generates graphs and vitals reports
                        </p>
                        <p className="text-center absolute sml:bottom-[-130px] md:bottom-[-150px] lg:bottom-[-115px] xl:bottom-[-150px] bottom-[-110px] left-0 right-0">
                            <button  onClick={handleButtonClick} className="bg-[#F74E86] rounded-[5px] shadow-sm text-[14px] text-white py-2 px-12">
                                Apply for Beta Access
                            </button>
                        </p>
                    </div>
                    <img className="xl:w-[1280px] xl:h-[618px]  mx-auto mt-[-40px] hidden lg:block" src={img_2} alt="" />
                    <img className="w-full h-full mt-[10px] block lg:hidden" src={mobile_top} alt="" />
                </div>


            </div>


        </>
    )
}

export default TopSection