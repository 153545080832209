import React, { useState, useEffect } from 'react';
import heart_logo from '../assets/images/logo.png'
import heart_logo_alt from '../assets/images/logo_alt.png'

const Navbar = () => {
    const [showNavbar, setShowNavbar] = useState(false);

    useEffect(() => {
        // Function to handle scroll event
        const handleScroll = () => {
            if (window.scrollY > 400) {
                setShowNavbar(true); // Show navbar when user starts scrolling
            } else {
                setShowNavbar(false); // Hide navbar when user is at the top
            }
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Empty dependency array means this effect runs only once


    const handleButtonClick = () => {
        window.open('https://bit.ly/AayuCareBeta', '_blank'); // Open in a new tab
    };

    var text = window.innerWidth > 500 ?  "Apply for Beta Access" : "Apply\xA0for\xA0Beta"

    

    return (
        <>
            <header className={`bg-[#6cb8c6] sticky z-50 top-0 w-full ${showNavbar ? 'block' : 'hidden'}`}>
                <nav className="mx-auto px-6 py-1 flex justify-between items-center">
                    <a href='#'>
                    <div className="flex items-center">
                        <img className="w-full sm:w-[60%] sml:w-[30%] md:w-[20%] lg:w-[30%] xl:w-[20%] 2xl:w-[20%]" src={heart_logo_alt} alt="" />
                        {/* <p className="ff sm:hidden sml:hidden md:block  text-white font-bold text-[30px] ml-6">Aayu Care</p> */}
                    </div>
                    </a>

                    <div className="flex sm:space-x-0 sml:space-x-2 md:space-x-6 lg:space-x-6 xl:space-x-6 2xl:space-x-8 py-4">
                        <a className="text-white sm:hidden sml:hidden md:block  ff text-[16px] cursor-pointer py-2 " href="#feature">Features</a>
                        <a className="text-white sm:hidden sml:hidden md:block  ff text-[16px] cursor-pointer py-2 " href="#faq">FAQs</a>
                        <button onClick={handleButtonClick} className="bg-[#F74E86] rounded-[5px] shadow-sm text-[14px] text-white py-2 px-8">
                           {text}   
                        </button>
                    </div>
                </nav>
            </header>


        </>
    )
}

export default Navbar