import React from 'react'
import img_3 from '../assets/images/privacy.png'
import img_4 from '../assets/images/insights.png'
import img_5 from '../assets/images/share.png'
import img_6 from '../assets/images/appointment.png'
import img_7 from '../assets/images/vault.png'


const Cards = () => {
    return (
        <>
            {/* Card 1 */}
            <div className='container mx-auto'>
                <div className="cards-main sm:w-[95%] mx-auto" >
                    <div className="sml:w-[100%] md:w-[95%] lg:w-[95%] xl:w-[88%] 2xl:w-[88%] mx-auto">
                        <div className="mb-4 md:mb-12 bg-[#d4f0f4] lg:w-[85%] xl:w-[75%] 2xl:w-[65%] mx-auto flex flex-col md:flex-row items-center rounded-[22px]">
                            <div className="sm:w-[100%] sml:w-[80%] md:w-[50%] lg:px-10 sm:py-6 sm:px-6 sml:px-0 sml:py-6 md:px-6 lg:py-12">
                                <h1 className="text-[30px] ff md:text-[30px] md:Pb-0 font-bold tracking-normal leading-normal 
                                text-[#525151]">
                                    Secure Medical Records Vault
                                </h1>
                                <p className="sml:text-[14px] sml:py-2 sm:py-2 ff md:text-[18px] text-[#505050]">
                                    Store your family's medical records in a secure vault. Easily upload records via the app, or share your records via WhatsApp/Email
                                </p>
                            </div>
                            <div className="md:w-[45%] px-8 md:px-0 pb-8 md:pb-0 pt-1 lg:w-[45%] xl:w-[45%] 2xl:w-[45%]">
                                <img className="" src={img_7} alt="banner image" />
                            </div>


                        </div>
                    </div>
                    {/* Card 2 */}
                    <div className="sml:w-[100%] md:w-[95%] lg:w-[95%] xl:w-[88%] 2xl:w-[88%] mx-auto">
                        <div className="single-card mb-4 md:mb-12 bg-[#d4f0f4] lg:w-[85%] xl:w-[75%] 2xl:w-[65%] mx-auto flex flex-col md:flex-row items-center rounded-[22px]">
                            <div className="sm:w-[100%] sml:w-[80%] md:w-[50%] lg:px-6 sm:py-6 sm:px-6 sml:px-0 sml:py-6 lg:py-12 ">
                                <h1 className="text-[30px] ff md:text-[30px] md:Pb-0 font-bold tracking-normal leading-normal 
                                text-[#525151]">
                                    Your Health, Your Privacy
                                </h1>
                                <p className="sml:text-[14px] sml:py-2 sm:py-2 ff md:text-[18px] text-[#505050]">
                                  We ensure your health data stays truly private. We secure your health records with robust end-to-end encryption, guaranteeing that even our servers can't read, or commercialise your records. Only you can access your data
                                </p>
                            </div>
                            <div className="md:w-[45%] px-8 md:px-0 pb-8 md:pb-0 pt-1 lg:w-[45%] xl:w-[45%] 2xl:w-[45%]">
                                <img className="" src={img_3} alt="banner image" />
                            </div>

                        </div>
                    </div>
                    {/* Card 3 */}
                    <div className="sml:w-[100%] md:w-[95%] lg:w-[95%] xl:w-[88%] 2xl:w-[88%] mx-auto">
                        <div className="mb-4 md:mb-12 bg-[#d4f0f4] lg:w-[85%] xl:w-[75%] 2xl:w-[65%] mx-auto flex flex-col md:flex-row items-center rounded-[22px]">
                            <div className="sm:w-[100%] md:px-6 sml:w-[80%] md:w-[50%] lg:px-10 sm:py-6 sm:px-6 sml:px-0 sml:py-6 lg:py-12">
                                <h1 className="text-[30px] ff md:text-[30px] md:Pb-0 font-bold tracking- leading-normal 
                                text-[#525151]">
                                    Understand Your Health Trends
                                </h1>
                                <p className="sml:text-[14px] sml:py-2 sm:py-2 ff md:text-[18px] text-[#505050]">
                                   We help you grasp valuable insights from your past lab test reports. Our user-friendly graphs provide a clear view of your health history, making it easy to identify trends in vital health markers
                                </p>
                            </div>
                            <div className="md:w-[45%] px-8 md:px-0 pb-8 md:pb-0 pt-1 lg:w-[45%] xl:w-[45%] 2xl:w-[45%]">
                                <img className="" src={img_4} alt="banner image" />
                            </div>
                        </div>
                    </div>
                    {/* Card 4 */}
                    <div className="sml:w-[100%] md:w-[95%] lg:w-[95%] xl:w-[88%] 2xl:w-[88%] mx-auto">
                        <div className="single-card mb-4 md:mb-12 bg-[#d4f0f4] lg:w-[85%] xl:w-[75%] 2xl:w-[65%] mx-auto flex flex-col md:flex-row items-center rounded-[22px]">

                            <div className="sm:w-[100%] sml:w-[80%] md:w-[50%]  lg:px-6 sm:py-6 sm:px-6 sml:px-0 sml:py-6 lg:py-12">
                                <h1 className="text-[30px] ff md:text-[30px] md:Pb-0 font-bold tracking-normal leading-normal 
                                text-[#525151]">
                                    Effortless Medical History Sharing
                                </h1>
                                <p className="sml:text-[14px] sml:py-2 sm:py-2 ff md:text-[18px] text-[#505050]">
                                   Changed doctors, need a medical second opinion, or facing insurance claim hassles? Say goodbye to paperwork. Share your full medical history securely with your doctor/insurer in one click
                                </p>
                            </div>
                            <div className="md:w-[45%] px-8 md:px-0 pb-8 md:pb-0 pt-1 lg:w-[45%] xl:w-[45%] 2xl:w-[45%]">
                                <img className="" src={img_5} alt="banner image" />
                            </div>

                        </div>
                    </div>
                     {/* Card 5 */}
                    <div className="sml:w-[100%] md:w-[95%] lg:w-[95%] xl:w-[88%] 2xl:w-[88%] mx-auto">
                        <div className="mb-4 md:mb-12 bg-[#d4f0f4] lg:w-[85%] xl:w-[75%] 2xl:w-[65%] mx-auto flex flex-col md:flex-row items-center rounded-[22px]">
                            <div className="sm:w-[100%] md:px-6 sml:w-[80%] md:w-[50%] lg:px-10 sm:py-6 sm:px-6 sml:px-0 sml:py-6 lg:py-12">
                                <br></br>
                                <h1 className="text-[30px] ff md:text-[30px] md:Pb-0 font-bold tracking- leading-normal 
                                text-[#525151]">
                                    Consult Top Doctors
                                </h1>
                                <p className="sml:text-[14px] sml:py-2 sm:py-2 ff md:text-[18px] text-[#505050]">
                                    Find experienced doctors across all specialities. Book an appointment for in-clinic consultation
                                </p><br></br>
                            </div>
                            <div className="md:w-[45%] px-8 md:px-0 pb-8 md:pb-0 pt-1 lg:w-[45%] xl:w-[45%] 2xl:w-[45%]">
                                <img className="" src={img_6} alt="banner image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cards