import React from 'react'
import TopSection from '../TopSection'
import Features from '../Features'
import Cards from '../Cards'
import Best_Medical from '../Best_Medical'
import FAQS from '../FAQS'
import Footer from '../Footer'
import Navbar from '../Navbar'

const Landing = () => {
  return (
    <>
      <Navbar/>
      <TopSection />
      <Features />
      <Cards />
      <FAQS/>
      <Best_Medical/>
      <Footer/>
      
     
      
    











    </>
  )
}

export default Landing